import React, { useEffect, useState } from "react";
import { Container, Grid, Icon, List, Image, Responsive } from "semantic-ui-react";

import LayoutInner from "../../components/layout/layout-inner";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import BodyContent from "../../components/body-content/body-content";
import CTA from "../../components/cta/cta";
import CookieUtils from '../../utils/cookie-utils';
import JobListing from "../../components/job-listing/job-listing";

import "./index.css";

const Page = () => {
  const [urlParams, setUrlParams] = useState('');

  
  useEffect(() => {
    setUrlParams(CookieUtils.analyticsConsentGiven() ? '' : '?dnt=1');
  }, []);

  // Client-side Runtime Data Fetching
  const [departments, setdepartments] = useState([])
  useEffect(() => {
    // get data from Green House api
    fetch("https://boards-api.greenhouse.io/v1/boards/rocksteadymusicschool/departments")
      .then(response => response.json()) // parse JSON from request
      .then((resultData) => {
        const deps = resultData
        .departments
        .filter(({ name }) => name === 'Band Leaders');
        setdepartments(deps)
      }) 
  }, [])

  // Pass along Greenhouse URL params for marketing campaigns
  // See tracking info on https://app2.greenhouse.io/jobboard/integration/documentation/api_board_hosted_apps
  const [source, setSource] = useState("rocksteady_www_no_referrer")
  useEffect(() => {
    // Get GH Source code from URL params
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ghSrc = urlParams.get('gh_src');
    if(ghSrc) {
      setSource(ghSrc)
    }
  }, [])

  const bandLeaderBenefits = [
    'Full time music teacher salary',
    '12 weeks paid holiday',
    'Full training and ongoing support',
    'Fuel package',
    'Discounts at music retailers',
    '24/7 online GP service and employee discounts',
    'Ongoing instrument development',
    'Employee discounts',
    'All-staff jams and inspiring away days',
    'The opportunity to make a positive impact on the world'
  ];

  const renderBenefitsList = (benefitList) => benefitList.map((benefit) => (
    <List.Item key={benefit}>
      <Icon name='check' className="blue-suede-shoes" />
      <List.Content>{benefit}</List.Content>
    </List.Item>
  ));
 
  return (
    <LayoutInner>
      <SEO
        title="Vocalist Careers | Rocksteady Music School"
        keywords={[`rocksteady`]}
        description="Being a Rocksteady Music Teacher is a perfect career path for a vocalist looking for a job with purpose. Explore music teaching careers with Rocksteady."
      />

      <Container>
        <SecondaryNavBar
          title="Careers"
          titleActive={true}
          titlePath="/careers/"
          product="generic"
          links={[]}
          cta="View Available Jobs"
          ctaLink="#view-available-jobs"
        />

        <h1>
          <img
            src="/images/careers/rach-banner.png"
            alt="Work with Rocksteady Music School"
            className="ui fluid image"
          />
        </h1>

        <BodyContent>
          <h2>Are you a vocalist looking for your dream job in music? This is it.</h2>
          <p>
            Are you an accomplished vocalist who would like to be part of a mission to empower
            as many children as possible and transform their lives through progressive and inclusive
            music education?
          </p>
          <p>
            We are looking for fantastic vocalists like you who can also play one of: guitar, bass, drums or keyboard.
          </p>
          <p>
            This is a perfect opportunity for a singer looking for full time paid work, a music teacher looking for jobs
            with purpose, or a vocalist looking for their next career challenge in a supportive environment.
          </p>
        </BodyContent>

        <BodyContent>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <CTA
              ctaText="View Available Jobs"
              ctaLink="#view-available-jobs"
              siteSection="generic"
            />
          </div>
        </BodyContent>

        <BodyContent>
          <Grid stackable>
            <Grid.Row>
              <Container>
                <h3>Vocalist & Band Leader Anna</h3>
                <Responsive {...Responsive.onlyMobile}>
                  <Image src="/images/careers/anna-3.png" size="large" floated="left" alt="Vocalist singing" />
                  <br />
                </Responsive>
                <Responsive {...Responsive.onlyTablet}>
                  <Image src="/images/careers/anna-3.png" style={{ width: '35%' }} floated="left" alt="Vocalist singing" />
                </Responsive>
                <Responsive {...Responsive.onlyComputer}>
                  <Image src="/images/careers/anna-3.png" size="medium" floated="left" alt="Vocalist singing" />
                </Responsive>
                <p>
                  Anna has been with Rocksteady since 2017. She joined her first
                  professional band "The Get up's" straight after graduating from university.
                  She has worked as a singer in hotels and on cruise ships, performing six
                  nights a week.
                  <br />
                  <br />
                  <b>The perfect job for vocalists</b>
                  <br />
                  <br />
                  There's plenty of time in the evenings, weekends and school holidays to
                  gig and write your own songs. Plus the training and support offered is
                  second to none with a dedicated academy for staff development.
                  <br />
                  <br />
                  "When I'm not teaching children, I'm working on my own music. I play gigs
                  every weekend and host open mic nights. It's a real bonus that I can do this
                  alongside my full time role"
                  <br />
                  <br />
                </p>
              </Container>
            </Grid.Row>
            <Grid.Row>
              <Container>
                <Image src="/images/careers/anna-2.png" size="large" floated="right" alt="Teacher setting up equipment" />
                <p>
                  <b>Why Anna loves working for Rocksteady</b>
                  <br />
                  <br />
                  "I've helped lots of children with confidence issues. I love
                  being part of that. Watching a child get that buzz from
                  performing when they didn't even think they'd be able to get
                  up on stage."
                  <br />
                  <br />
                  "I taught a young girl who, due to disabilities from birth, had
                  trouble communicating. I was told not to expect too much
                  from her, but after twenty minutes she was singing 'We Will
                  Rock You'. The headteacher came back into the room to
                  watch her and had tears in her eyes, the whole experience
                  gave me goose bumps!"
                  <br />
                  <br />
                  <b>Why Anna applied to be a Band Leader at Rocksteady</b>
                  <br />
                  <br />
                  "I instantly thought it looked amazing, I couldn't believe there was a job
                  out there like it! I was nervous, as I felt weaker
                  on some instruments, but I instantly sent off my application. I'm much more confident
                  on all the instruments now, thanks to all the training available."
                </p>
              </Container>
            </Grid.Row>
          </Grid>
        </BodyContent>

        <BodyContent>
          <Grid stackable style={{ background: "#D2F0FB", padding: 24 }} id="view-available-jobs">
            <Grid.Row columns={1}>
              <h3>Music Teacher Benefits</h3>
            </Grid.Row>
            <Grid.Row columns={2} only="computer tablet">
              <Grid.Column>
                <List relaxed>{renderBenefitsList(bandLeaderBenefits.slice(0, -5))}</List>
              </Grid.Column>
              <Grid.Column>
                <List relaxed>{renderBenefitsList(bandLeaderBenefits.slice(-5))}</List>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} only="mobile">
              <Grid.Column>
                <List relaxed>{renderBenefitsList(bandLeaderBenefits)}</List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </BodyContent>

        <BodyContent>
          <p id="view-available-jobs">Rocksteady Music School is the world's leading rock and pop music teaching service for primary schools, teaching band lessons to tens of thousands of pupils every week, helping to inspire the next generation to discover their love of music.</p>
          <p>As a company that is proud to inspire children across the UK every week, your dream music job might be just one application away. Whether you're looking for a full-time music job, you're currently a music teacher looking for jobs with purpose, a musician looking for work, or someone looking for their next career challenge in a supportive environment, we would love to hear from you.</p>
        </BodyContent>

        <BodyContent>
          <Grid columns={1} style={{ background: "#f5f5f4", padding: 24 }}>
            <Grid.Column>
              <h2>Current Job Openings</h2>
              <JobListing departments={departments} source={source} showDepartmentTitles={false} />
            </Grid.Column>
          </Grid>
        </BodyContent>

        <BodyContent>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2>Not a job in your area?</h2>
            <p style={{ textAlign: 'center' }}>Register your interest for when we come to your location.</p>
            <CTA
              ctaText="Register Interest"
              ctaLink="https://boards.greenhouse.io/rocksteadymusicschool/jobs/4505704002?gh_src=rocksteady_www_no_referrer"
              siteSection="generic"
            />
          </div>
        </BodyContent>

        {/* Video has been taken down temporarily - reinstate when replacement is ready */}
        {/*<BodyContent>
          <div className="videoWrapper wide">
            <iframe
              src={`https://player.vimeo.com/video/[id goes here]${urlParams}`}
              width="640"
              height="480"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Rocksteady Music School Careers"
            ></iframe>
          </div>
        </BodyContent>*/}

      </Container>
    </LayoutInner>
  );
}

export default Page;

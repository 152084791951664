import React from "react";

import style from "./style.module.css";
import CTA from "../../components/cta";
import { Icon } from "semantic-ui-react";

// gh_src 
// See tracking info on https://app2.greenhouse.io/jobboard/integration/documentation/api_board_hosted_apps
const GREENHOUSE_SRC_PARAM_NAME="gh_src"

const Job = ({job, source}) => {
  return(
    <span>
      <a href={`${job.absolute_url}?${GREENHOUSE_SRC_PARAM_NAME}=${source}`} target="_blank" rel="noreferrer">
        <Icon name="arrow right" /> {job.title}
      </a>
      <br/>
      <span className={style.jobLocation}>{job.location.name}</span>
    </span>
  )
}

const JobListing = ({ departments, source, showDepartmentTitles = true }) => {
  if (!departments) {
    // If the API is slow or down, offer a straight link to the listings hosted on Greenhouse
    return (<CTA ctaLink={"https://boards.greenhouse.io/rocksteadymusicschool/"} ctaText="See Job Openings" siteSection="generic" />)
  }
  return (
    <div>
      {
        departments.map((department) => {
          const jobs = department.jobs;

          // Greenhouse returns all departments even if no jobs open
          if (jobs.length === 0) {
            return null;
          }
          
          return(
            <div key={`dep-${department.id}`}>
              
              {/* Anchor link for jumping down the page from age range buttons */}
              <span className={style.quickLinkAnchor} id={`department-${department.id}`} />
              
              {showDepartmentTitles && <h3>{department.name}</h3>}
              <ul className={style.jobList}>
              {
                jobs.map((job) => {
                  return(
                    <li className={style.jobListItem} key={`job-${job.id}`}>
                      <Job job={job} source={source} />
                    </li>
                  )
                })
              }
              </ul>
            </div>
          )
        })
      }
    </div>
  );
};

export default JobListing;
